export class DataHelper {

  /***
   * Checks if the item is not null or undefined
   * @param item
   */
  public static hasValue(item: any): boolean {
    return item !== null && item !== undefined;
  }

  /***
   * Checks if value has any elements
   * @param value
   */
  public static hasAnyElement(value: any[]): boolean {
    return this.hasValue(value) && Array.isArray(value) && value.length > 0;
  }

  /***
   * Check if value has only one element
   * @param value
   */
  public static hasSingleElement(value: any[]): boolean {
    return this.hasValue(value) && Array.isArray(value) && value.length === 1;
  }

  /***
   * Check if value is not empty
   * @param value
   */
  public static isNotEmpty(value: any): boolean {
    return this.hasValue(value) && value !== '';
  }

  /***
   * Check if all values are not null or undefined
   * @param args
   */
  public static allNotNull(...args: any[]): boolean {
    return args.findIndex(i => DataHelper.hasValue(i)) > -1;
  }

  /***
   * Check if at least one value is null or undefined
   * @param args
   */
  public static atLeastOneNull(...args: any[]): boolean {
    return args.findIndex(i => !DataHelper.hasValue(i)) > -1;
  }

  /***
   * Check if item contains value
   * @param item
   * @param value
   */
  public static containsValue(item: any, value: any): any {
    if (!DataHelper.hasValue(value)) { return true; }
    if (item instanceof Object) {
      return Object.values(item)?.some((val: any) => this.containsValue(val, value));
    } else if (item instanceof String) {
      return item?.toLowerCase()?.indexOf(value?.toString()?.trim()?.toLowerCase()) > -1;
    } else {
      return this.hasValue(item) ? item?.toString()?.toLowerCase()?.indexOf(value?.toString()?.trim()?.toLowerCase()) > -1 : false;
    }
  }

  /***
   * Create array from number
   * @param value
   */
  public static arrayFromNumber(value: number): number[] {
    return Array.from({length: value}, (_, i) => i + 1);
  }
}
